<script lang="ts">
    import Button from "$components/tui/Button.svelte"
    import type { Color } from "$components/tui/Button.svelte"
    import { escapeDownEvent$ } from "$lib/keys"
    import { SvelteComponent, getContext, onDestroy } from "svelte"
    import Modal from "./Modal.svelte"
    import type { IModalVC } from "./ModalVC.svelte"
    import lang from "$lang"

    export let title: string
    export let text: string | null = null

    // TODO: these `$lang.*` are not reactive
    export let confirmText: string = $lang.Confirm()
    export let confirmColor: Color = "blue"
    export let cancelText: string = $lang.Cancel()
    export let cancelColor: Color = "gray"

    export let confirmAction: () => void

    let modalVC = getContext<IModalVC>("ModalVC")

    let elem: HTMLButtonElement | null = null

    let subscriptionEscape = escapeDownEvent$.subscribe(() => {
        clickCancel()
        // to stop pressing escape from focussing the button leading to this modal
        elem?.focus()
    })
    onDestroy(() => subscriptionEscape.unsubscribe())

    function clickConfirm() {
        modalVC.pop()
        confirmAction()
    }
    function clickCancel() {
        modalVC.pop()
    }
</script>

<Modal>
    <div class="settings card max-w-xs bg-step-000">
        <div>
            <h1>{title}</h1>
            {#if text}
                <p class="whitespace-pre-line">{text}</p>
            {/if}
        </div>
        <div class="!grid grid-cols-2 gap-2">
            <Button color={cancelColor} on:click={clickCancel}>{cancelText}</Button>
            <Button color={confirmColor} on:click={clickConfirm}>{confirmText}</Button>
            <!-- to stop pressing escape from focussing the button leading to this modal -->
            <button class="absolute" bind:this={elem}></button>
        </div>
    </div>
</Modal>
